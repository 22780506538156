import {
	passWord,
	emailCode,
	checkEmail,
	email,
	tellCode,
	tell,
	verifypaypwdcode,
	modifypaypassword,
	paypwdcode,
	pwdmobliecode
} from "@/api/member/security"
import {
	info
} from "@/api/member/info"
import {
	captcha
} from "@/api/website"
export default {
	name: "security",
	components: {},
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("โปรดป้อนรหัสผ่านใหม่"))
			} else if (value == this.passWordForm.oldPass) {
				callback(new Error("รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม！"))
			} else {
				if (this.passWordForm.checkPass !== "") {
					this.$refs.passWordRef.validateField("checkPass")
				}
				callback()
			}
		}
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("กรุณาใส่รหัสผ่านของคุณอีกครั้ง"))
			} else if (value !== this.passWordForm.pass) {
				callback(new Error("ป้อนรหัสผ่านสองครั้งไม่สอดคล้องกัน!"))
			} else {
				callback()
			}
		}
		var validateTellPass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("โปรดป้อนรหัสผ่านใหม่"))
			} else if (value == this.tellPassForm.oldPass) {
				callback(new Error("รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม！"))
			} else {
				if (this.tellPassForm.checkPass !== "") {
					this.$refs.tellPassRef.validateField("checkPass")
				}
				callback()
			}
		}
		var validateTellPass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("กรุณาใส่รหัสผ่านของคุณอีกครั้ง"))
			} else if (value !== this.tellPassForm.pass) {
				callback(new Error("ป้อนรหัสผ่านสองครั้งไม่สอดคล้องกัน!"))
			} else {
				callback()
			}
		}
		var checkemail = (rules, value, callback) => {
			const regEmail = /^\w+@\w+(\.\w+)+$/
			if (regEmail.test(value)) {
				return callback()
			}
			callback(new Error("โปรดป้อนที่อยู่อีเมลที่ถูกต้อง"))
		}
		var checkTell = (rules, value, callback) => {
			const regMobile = /^1[3|4|5|6|7|8|9][0-9]{9}$/
			if (regMobile.test(value)) {
				return callback()
			}
			callback(new Error("โปรดป้อนหมายเลขโทรศัพท์มือถือที่ถูกต้อง"))
		}
		return {
			type: "all",
			passWordForm: {
				oldPass: "",
				pass: "",
				checkPass: ""
			},
			emailForm: {
				email: "",
				code: "", //รหัสยืนยันอีเมล
				emailDynacode: "", //ส่งอีเมลรหัสยืนยันแบบไดนามิก
				emailCodeText: "",
				key: "",
				currEmail: ""
			},
			passWordRules: {
				oldPass: [{
					required: true,
					message: "กรุณาใส่รหัสผ่านเดิม",
					trigger: "blur"
				}],
				pass: [{
					required: true,
					validator: validatePass,
					trigger: "blur"
				}],
				checkPass: [{
					required: true,
					validator: validatePass2,
					trigger: "blur"
				}]
			},
			emailRules: {
				email: [{
					required: true,
					message: "โปรดป้อนที่อยู่อีเมลที่ถูกต้อง",
					trigger: "blur"
				},
				{
					validator: checkemail,
					trigger: "blur"
				}
				],
				code: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}],
				emailDynacode: [{
					required: true,
					message: "โปรดป้อนรหัสยืนยันแบบไดนามิก",
					trigger: "blur"
				}]
			},
			captcha: {
				id: "",
				img: ""
			},
			seconds: 120,
			timer: null,
			isSend: false,
			isMobileSend: false,
			tellForm: {
				tell: "",
				code: "", //รหัสยืนยันอีเมล
				tellDynacode: "", //ส่งอีเมลรหัสยืนยันแบบไดนามิก
				tellCodeText: "",
				key: "",
				currTell: ""
			},
			tellRules: {
				tell: [{
					required: true,
					message: "โปรดป้อนหมายเลขโทรศัพท์มือถือที่ถูกต้อง",
					trigger: "blur"
				},
				{
					validator: checkTell,
					trigger: "blur"
				}
				],
				code: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}],
				tellDynacode: [{
					required: true,
					message: "โปรดป้อนรหัสยืนยันแบบไดนามิก",
					trigger: "blur"
				}]
			},
			isClick: true,
			payCodeText: 'รับรหัสยืนยัน',
			step: 0,
			payCode: '', // รหัสแบบไดนามิก
			payPassword: '', // รหัสผ่านการชําระเงิน
			payRepassword: '', // ใช้รหัสผ่านการชําระเงินซ้ํา
			payKey: '', // SMSkey
			payInput: '',
			palceText: 'ป้อนรหัสยืนยันทาง SMS',
			memberInfo: {},
			tellPassForm: {
				code: "",
				tellPassCodeText: "",
				key: "",
				tellPassDynacode: "",
				pass: '',
				checkPass: ''
			},
			tellPassRules: {
				code: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}],
				tellPassDynacode: [{
					required: true,
					message: "โปรดป้อนรหัสยืนยันแบบไดนามิก",
					trigger: "blur"
				}],
				pass: [{
					required: true,
					validator: validateTellPass,
					trigger: "blur"
				}],
				checkPass: [{
					required: true,
					validator: validateTellPass2,
					trigger: "blur"
				}]
			},
			loading: true,
			yes: true
		}
	},
	created() {
		this.getcaptcha()
		this.seconds = 120
		this.tellForm.tellCodeText = "รับโค้ดแบบไดนามิก"
		this.emailForm.emailCodeText = "รับโค้ดแบบไดนามิก"
		this.tellPassForm.tellPassCodeText = "รับโค้ดแบบไดนามิก"
		this.isSend = false
		this.isMobileSend = false
		clearInterval(this.timer)
		this.getInfo()
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		//การได้มาซึ่งข้อมูลส่วนบุคคล
		async getInfo() {
			await info()
				.then(res => {
					if (res.code == 0) {
						this.memberInfo = res.data
						this.emailForm.currEmail = res.data.email
						this.tellForm.currTell = res.data.mobile
					}
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					this.$notify.error({title: 'Error',message: err.message});
				})
		},
		async edit(type) {
			await this.getInfo()
			if (type == 'payPassWord') {
				if (!this.tellForm.currTell) {
					this.$confirm("คุณยังไม่ได้เชื่อมโยงหมายเลขโทรศัพท์ของคุณ，กรุณาผูกหมายเลขโทรศัพท์มือถือของคุณก่อน？", "ข้อมูลที่รวดเร็ว", {
						confirmButtonText: "ยืนยัน",
						cancelButtonText: "ยกเลิก",
						title: "คำเตือน",type: "warning"
					}).then(res => {
						if (res = 'confirm') {
							this.type = 'tell'
						} else {
							this.type = 'all'
						}
					})
				} else {
					this.type = type
				}
			} else {
				this.type = type
			}
		},
		//รับรหัสยืนยัน
		getcaptcha() {
			captcha({
				captcha_id: this.captcha.id
			})
				.then(res => {
					if (res.code >= 0) {
						this.captcha = res.data
						this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				})
		},
		//เปลี่ยนรหัสผ่าน
		save() {
			this.$refs.passWordRef.validate(valid => {
				if (valid) {
					passWord({
						new_password: this.passWordForm.pass,
						old_password: this.passWordForm.oldPass
					})
						.then(res => {
							this.$notify({
								message: "เปลี่ยนรหัสผ่านสําเร็จแล้ว",
								title: 'เรียบร้อยแล้ว',type: "success"
							})
							this.type = "all"
							this.$store.dispatch("member/member_detail", {
								refresh: 1
							})
							this.passWordForm.pass = ""
							this.passWordForm.oldPass = ""
							this.passWordForm.checkPass = ""
						})
						.catch(err => {
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
		// ตรวจสอบว่ามีกล่องจดหมายอยู่หรือไม่
		async getCheckEmail() {
			let result = await checkEmail({
				email: this.emailForm.email
			})
				.then(res => {
					if (res.code != 0) {
						this.$notify({
							message: res.message,
							title: 'เรียบร้อยแล้ว',type: "success"
						})
						return false
					}
					return true
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				})
			return result
		},
		//ผูกที่อยู่อีเมล
		async bindEmail() {
			this.$refs.emailRef.validate(valid => {
				if (valid) {
					email({
						email: this.emailForm.email,
						captcha_id: this.captcha.id,
						captcha_code: this.emailForm.code,
						code: this.emailForm.emailDynacode,
						key: this.emailForm.key
					})
						.then(res => {
							if (res.code == 0) {
								this.$notify({
									message: "กล่องจดหมายถูกผูกไว้เรียบร้อยแล้ว",
									title: 'เรียบร้อยแล้ว',type: "success"
								})
								this.type = "all"
								this.emailForm.email = ""
								this.emailForm.code = ""
								this.emailForm.emailDynacode = ""
								clearInterval(this.timer)
								this.getcaptcha()
							}
						})
						.catch(err => {
							this.getcaptcha()
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
		//รับรหัสยืนยันทางโทรศัพท์
		async gettellCode() {
			if (!this.isMobileSend) {
				this.isMobileSend = true
				await tellCode({
					mobile: this.tellForm.tell,
					captcha_id: this.captcha.id,
					captcha_code: this.tellForm.code
				})
					.then(res => {
						let data = res.data
						if (data.key) {
							if (this.seconds == 120 && this.timer == null) {
								this.timer = setInterval(() => {
									this.seconds--
									this.tellForm.tellCodeText = "ส่ง(" + this.seconds + "s)"
								}, 1000)
							}
							this.tellForm.key = data.key
						} else {
							this.$notify({
								message: res.message,
								title: "คำเตือน",type: "warning"
							})
							this.isMobileSend = false
						}
					})
					.catch(err => {
						this.getcaptcha()
						this.$notify.error({title: 'Error',message: err.message});
						if(err.message == 'หมายเลขโทรศัพท์ปัจจุบันมีอยู่แล้ว'){
							this.isMobileSend = false
						}
					})
			} else {
				this.$notify({
					message: "อย่าคลิกซ้ําๆ",
					title: "คำเตือน",type: "warning"
				})
			}
		},
		//ผูกหมายเลขโทรศัพท์มือถือ
		bindtell() {
			this.$refs.tellRef.validate(valid => {
				if (valid) {
					tell({
						mobile: this.tellForm.tell,
						captcha_id: this.captcha.id,
						captcha_code: this.tellForm.code,
						code: this.tellForm.tellDynacode,
						key: this.tellForm.key
					})
						.then(res => {
							if (res.code == 0) {
								this.$notify({
									message: "หมายเลขโทรศัพท์มือถือถูกผูกไว้เรียบร้อยแล้ว",
									title: 'เรียบร้อยแล้ว',type: "success"
								})
								this.type = "all"
								this.tellForm.email = ""
								this.tellForm.code = ""
								this.tellForm.emailDynacode = ""
								clearInterval(this.timer)
								this.getcaptcha()
							}
						})
						.catch(err => {
							this.getcaptcha()
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
		//รับข้อมูลกล่องป้อนข้อมูล
		input(val) {
			this.isClick = false
			if (this.step == 0 && val.length == 4) {
				this.payCode = val;
			} else if (this.step == 1 && val.length == 6) {
				this.payPassword = val;
			} else if (val.length == 6) {
				this.payRepassword = val;
			}

		},
		//รับรหัสยืนยันการชําระเงิน
		sendMobileCode() {
			if (!this.isSend) {
				paypwdcode().then(res => {
					let data = res.data;
					if (data.key) {
						if (this.seconds == 120 && this.timer == null) {
							this.timer = setInterval(() => {
								this.seconds--;
								this.payCodeText =
									"ส่ง(" + this.seconds + "s)";
							}, 1000);
						}
						this.payKey = data.key;
					} else {
						this.$notify({
							message: res.message,
							title: 'คำเตือน',type: 'warning'
						});
						this.isSend = false;
					}
				})
					.catch(err => {
						this.$notify.error({title: 'Error',message: err.message});
					});
			}
		},
		//คลิก ตกลง
		bindPayPwd() {
			clearInterval(this.timer)
			const reg = /^[0-9]*$/
			if (this.step == 0) {
				verifypaypwdcode({
					code: this.payCode,
					key: this.payKey
				}).then(res => {
					if (res.code == 0) {
						this.$refs.input.clear();
						this.step = 1;
						this.palceText = 'กรุณาตั้งรหัสผ่านการชําระเงิน'
					}
				}).catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				})
			} else if (this.step == 1) {
				if (reg.test(this.$refs.input.value)) {
					this.$refs.input.clear();
					this.step = 2;
					this.palceText = 'กรุณาใส่อีกครั้ง'
				} else {
					this.$message.error('กรุณาใส่หมายเลข')
					this.step = 1;
					this.$refs.input.clear();
				}
			} else {
				if (this.payPassword == this.payRepassword) {
					if (this.isSub) return;
					this.isSub = true;
					modifypaypassword({
						key: this.payKey,
						code: this.payCode,
						password: this.payPassword
					}).then(res => {
						if (res.code >= 0) {
							this.$notify({
								message: "เปลี่ยนรหัสผ่านการชําระเงินเรียบร้อยแล้ว",
								title: 'เรียบร้อยแล้ว',type: "success"
							})
							this.type = 'all'
							this.step = 0,
								this.$refs.input.clear();
							clearInterval(this.timer)
						}
					}).catch(err => {
						this.$notify.error({title: 'Error',message: err.message});
					})
				} else {
					this.$message.error('รายการรหัสผ่านสองรายการจะแตกต่างกัน');
					this.initInfo();
				}
			}
		},
		//ข้อมูลการเริ่มต้น
		initInfo() {
			this.step = 1;
			this.palceText = 'กรุณาตั้งรหัสผ่านการชําระเงิน'
			this.password = '';
			this.repassword = '';
			this.oldpassword = '';
			this.isSub = false;
			this.$refs.input.clear();
		},
		//รับโค้ดแบบไดนามิก
		getTellPassCode() {
			if (!this.isSend) {
				this.isSend = true
				pwdmobliecode({
					captcha_id: this.captcha.id,
					captcha_code: this.tellPassForm.code,
				})
					.then(res => {
						let data = res.data
						if (data.key) {
							if (this.seconds == 120 && this.timer == null) {
								this.timer = setInterval(() => {
									this.seconds--
									this.tellPassForm.tellPassCodeText = "ส่ง(" + this.seconds + "s)"
								}, 1000)
							}
							this.tellPassForm.key = data.key
						} else {
							this.$notify({
								message: res.message,
								title: "คำเตือน",type: "warning"
							})
							this.isSend = false
						}
					})
					.catch(err => {
						this.getcaptcha()
						this.$notify.error({title: 'Error',message: err.message});
					})
			} else {
				this.$notify({
					message: "อย่าคลิกซ้ําๆ",
					title: "คำเตือน",type: "warning"
				})
			}
		},
		//เปลี่ยนรหัสผ่าน
		tellPassSave() {
			this.$refs.tellPassRef.validate(valid => {
				if (valid) {
					passWord({
						new_password: this.tellPassForm.pass,
						code: this.tellPassForm.tellPassDynacode,
						key: this.tellPassForm.key,
					})
						.then(res => {
							this.$notify({
								message: "เปลี่ยนรหัสผ่านสําเร็จแล้ว",
								title: 'เรียบร้อยแล้ว',type: "success"
							})
							this.type = "all"
							this.$store.dispatch("member/member_detail", {
								refresh: 1
							})
							this.tellPassForm.pass = ""
							this.tellPassForm.checkPass = ""
							this.tellPassForm.key = ""
							this.tellPassForm.tellPassDynacode = ""
						})
						.catch(err => {
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
	},
	filters: {
		mobile(mobile) {
			return mobile.substring(0, 4 - 1) + '****' + mobile.substring(6 + 1);
		}
	},
}
