import http from "../../utils/http"

/**
 * รับข้อมูลผู้ใช้
 * @param {object} params
 */
export function info(params) {
    return http({
        url: "/api/member/info",
        data: params,
        forceLogin: true
    })
}
/**
 * แก้ไขชื่อเล่น
 * @param {object} params
 */
export function nickName(params) {
    return http({
        url: "/api/member/modifynickname",
        data: params,
        forceLogin: true
    })
}
/**
 * แก้ไขอวาตาร์ของคุณ
 * @param {object} params
 */
export function headImg(params) {
    return http({
        url: "/api/member/modifyheadimg",
        data: params,
        forceLogin: true
    })
}
