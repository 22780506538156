import http from "../../utils/http"

/**
 * เปลี่ยนรหัสผ่าน
 * @param {object} params
 */
export function passWord(params) {
    return http({
        url: "/api/member/modifypassword",
        data: params,
        forceLogin: true
    })
}
/**
 * ส่งรหัสแบบไดนามิก SMS
 * @param {object} params
 */
export function tellCode(params) {
    return http({
        url: "/api/member/bindmobliecode",
        data: params,
        forceLogin: true
    })
}
/**
 * ผูกหมายเลขโทรศัพท์มือถือ
 * @param {object} params
 */
export function tell(params) {
    return http({
        url: "/api/member/modifymobile",
        data: params,
        forceLogin: true
    })
}
/**
 * ตรวจสอบว่ามีกล่องจดหมายอยู่หรือไม่
 * @param {object} params
 */
export function checkEmail(params) {
    return http({
        url: "/api/member/checkemail",
        data: params,
        forceLogin: true
    })
}
/**
 * ส่งอีเมลรหัสแบบไดนามิก
 * @param {object} params
 */
export function emailCode(params) {
    return http({
        url: "/api/member/bingemailcode",
        data: params,
        forceLogin: true
    })
}
/**
 * ผูกที่อยู่อีเมล
 * @param {object} params
 */
export function email(params) {
    return http({
        url: "/api/member/modifyemail",
        data: params,
        forceLogin: true
    })
}
/**
 * การยืนยันแคปต์ชา
 * @param {object} params
 */
export function verifypaypwdcode(params) {
    return http({
        url: "/api/member/verifypaypwdcode",
        data: params,
        forceLogin: true
    })
}
/**
 * รับรหัสผ่านก่อนหน้า
 * @param {object} params
 */
export function modifypaypassword(params) {
    return http({
        url: "/api/member/modifypaypassword",
        data: params,
        forceLogin: true
    })
}
/**
 * โค้ดแบบไดนามิกบนอุปกรณ์เคลื่อนที่
 * @param {object} params
 */
export function paypwdcode(params) {
    return http({
        url: "/api/member/paypwdcode",
        data: params,
        forceLogin: true
    })
}
/**
 * รหัสผ่านโทรศัพท์มือถือรหัสแบบไดนามิก
 * @param {object} params
 */
export function pwdmobliecode(params) {
    return http({
        url: '/api/member/pwdmobliecode',
        data: params,
        forceLogin: true
    })
}

